import Neon, { wallet as NeonWallet } from '@cityofzion/neon-js';
import { reverseHex } from '../convert';

function addressToHash(address) {
  return NeonWallet.getScriptHashFromAddress(address);
}

function addressToHex(address) {
  return reverseHex(addressToHash(address));
}

function isValidAddress(address) {
  return Neon.is.address(address) && address.startsWith('N');
}

export default {
  install() {},
  addressToHash,
  addressToHex,
  isValidAddress,
};
